import { combineReducers } from "redux";
import trains from "./trains";
import travelers from "./travelers";
import activeSeats from "./reservedSeats"
import steps from "./steps";
import activeStation from "./stations";
import tickets from "./tickets";
import price from "./price";
import discount from "./discount";

export default combineReducers({
    trains,
    travelers,
    activeSeats,
    steps,
    activeStation,
    tickets,
    price,
    discount,
});
