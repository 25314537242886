import React, {useEffect, useState} from "react";
import {useDispatch, useSelector } from "react-redux";
import {setTotalPrice} from "../actions/price";

const CheckoutPrice = () => {
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const activeStation = useSelector(state => state.activeStation);
    const { seats } = useSelector(state => state.activeSeats);
    const price = useSelector(state => state.price);

    return (
        price &&
        <>
            <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Pris</h3>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {price} SEK
            </ul>
        </>
    );
};

export default CheckoutPrice;
