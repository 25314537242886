import {useSelector} from "react-redux";
import React from "react";
import classNames from "classnames";

const TravelerList = () => {
    const travelers = useSelector(state => state.travelers);

    return (
        <>
            {travelers.total > 0 && (
            <>
                <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Resenärer</h3>
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                { Object.keys(travelers).map((index) => (
                    index !== 'total' &&
                    <li key={index} className="col-span-1 flex shadow-sm rounded-md">
                        <div className={classNames("flex-shrink-0 flex items-center justify-center w-16 text-sm font-medium rounded-l-md text-white", { "bg-indigo-400": travelers[index].count > 0, "bg-gray-200": travelers[index].count == 0})}>
                            {travelers[index].count + 'st'}
                        </div>
                        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                            <span className="text-gray-900 hover:text-gray-600">
                                {travelers[index].name}
                            </span>
                                <p className="text-gray-500">{travelers[index].description}</p>
                            </div>
                        </div>
                    </li>
                ))}
                </ul>
            </>
            )}
        </>
    );
};

export default TravelerList;
