import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setTotalPrice} from "../actions/price";

const CalculatedPrice = () => {
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const activeStation = useSelector(state => state.activeStation);
    const { seats } = useSelector(state => state.activeSeats);
    const price = useSelector(state => state.price);
    const travelers = useSelector(state => state.travelers);

    useEffect(() => {
        let totalPrice = 0;

        Object.keys(travelers).map((index) => {
            switch(travelers[index].description) {
                case '18+':
                    totalPrice += travelers[index].count * activeStation.adultPrice;
                    break;
                case '13-17 år':
                    totalPrice += travelers[index].count * activeStation.youthPrice;
                    break;
                case '7-12 år':
                    totalPrice += travelers[index].count * activeStation.childPrice;
                    break;
                case '0-6 år':
                    totalPrice += travelers[index].count * activeStation.infantPrice;
                    break;
                default:
            }
        });

        seats.map((seat, i) => {
            totalPrice += seat.firstClass ? activeStation.adultPriceFirstClass-activeStation.adultPrice : 0;
        });

        setTotal(totalPrice);
    },[seats]);

    useEffect(() => {
        dispatch(setTotalPrice(total));
    },[total]);

    return (
        seats &&
        <>
            <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Pris</h3>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {total} kr
            </ul>
        </>
    );
};

export default CalculatedPrice;
