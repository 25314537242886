import {useSelector} from "react-redux";
import React from "react";

const ReservationInfoHeader = () => {

    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <h2 className="bg-gray-50 text-md uppercase text-gray-500">Din bokning</h2>
            </div>
        </div>
    );
};

export default ReservationInfoHeader;
