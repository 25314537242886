import {useSelector} from "react-redux";
import React from "react";

const SelectedTrain = () => {
    const { currentTrain } = useSelector(state => state.trains);

    return (
        <>
            {currentTrain &&
            <>
                <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Resa</h3>
                <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                        <span className="text-gray-900 font-medium hover:text-gray-600">
                            {currentTrain.heading}, {currentTrain.date}
                        </span>
                    </div>
                </div>
            </>
            }
        </>
    );
};

export default SelectedTrain;
