import React, {useState} from "react";
import {useSelector} from "react-redux";

import Seat from "../../components/Seat";
import EmptySeat from "../../components/EmptySeat";

export default function B1S(props) {
    const { id, reservedSeats, announcedNumber, number } = props;

    const activeSeats = useSelector(state => state.activeSeats);

    const isAvailable = (carriageId, seatNumber) => {
        return !reservedSeats.some(seat => {
            return (seat.carriageId === carriageId && seat.number === seatNumber)
        });
    };

    const isActive = (carriage, number) => {
        return Object.keys(activeSeats).some(seat => (seat.carriage === carriage && seat.seat === number))
    };

    return (
        <div className="wagon-box">
            <div className="vestibule">
                <div className="wc-bottom-left">
                    WC
                </div>
            </div>
            <div className="wall-row">
                <span className="wall"/>
                <span className="wall"/>
            </div>
            <div className="compartment-lounge">
                Lounge
            </div>
            <div className="wall-row">
                <span className="wall"/>
                <span className="wall"/>
                <span className="wall"/>
                <span className="wall"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="6" available={isAvailable(id, 6)}
                      active={isActive(id, 6)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="5" available={isAvailable(id, 5)}
                      active={isActive(id, 5)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="2" available={isAvailable(id, 2)}
                      active={isActive(id, 2)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="1" available={isAvailable(id, 1)}
                      active={isActive(id, 1)}/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="7" reverse={true}
                      available={isAvailable(id, 7)} active={isActive(id, 7)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="8" reverse={true}
                      available={isAvailable(id, 8)} active={isActive(id, 8)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="4" reverse={true}
                      available={isAvailable(id, 4)} active={isActive(id, 4)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="3" reverse={true}
                      available={isAvailable(id, 3)} active={isActive(id, 3)}/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="14" available={isAvailable(id, 14)}
                      active={isActive(id, 14)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="13" available={isAvailable(id, 13)}
                      active={isActive(id, 13)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="10" available={isAvailable(id, 10)}
                      active={isActive(id, 10)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="9" available={isAvailable(id, 9)}
                      active={isActive(id, 9)}/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="15" reverse={true}
                      available={isAvailable(id, 15)} active={isActive(id, 15)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="16" reverse={true}
                      available={isAvailable(id, 16)} active={isActive(id, 16)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="12" reverse={true}
                      available={isAvailable(id, 12)} active={isActive(id, 12)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="11" reverse={true}
                      available={isAvailable(id, 11)} active={isActive(id, 11)}/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="22" available={isAvailable(id, 22)}
                      active={isActive(id, 22)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="21" available={isAvailable(id, 21)}
                      active={isActive(id, 21)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="18" available={isAvailable(id, 18)}
                      active={isActive(id, 18)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="17" available={isAvailable(id, 17)}
                      active={isActive(id, 17)}/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="23" reverse={true}
                      available={isAvailable(id, 23)}
                      active={isActive(id, 23)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="24" reverse={true}
                      available={isAvailable(id, 24)}
                      active={isActive(id, 24)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="20" reverse={true}
                      available={isAvailable(id, 20)}
                      active={isActive(id, 20)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="19" reverse={true}
                      available={isAvailable(id, 19)}
                      active={isActive(id, 19)}/>
            </div>
            <div className="wall-row">
                <span className="wall"/>
                <span className="wall"/>
                <span className="wall"/>
                <span className="wall"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="30" available={isAvailable(id, 30)}
                      active={isActive(id, 30)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="29" available={isAvailable(id, 29)}
                      active={isActive(id, 29)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="26" available={isAvailable(id, 26)}
                      active={isActive(id, 26)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="25" available={isAvailable(id, 25)}
                      active={isActive(id, 25)}/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="31" reverse={true}
                      available={isAvailable(id, 31)}
                      active={isActive(id, 31)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="32" reverse={true}
                      available={isAvailable(id, 32)}
                      active={isActive(id, 32)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="28" reverse={true}
                      available={isAvailable(id, 28)}
                      active={isActive(id, 28)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="27" reverse={true}
                      available={isAvailable(id, 27)}
                      active={isActive(id, 27)}/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="38" available={isAvailable(id, 38)}
                      active={isActive(id, 38)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="37" available={isAvailable(id, 37)}
                      active={isActive(id, 37)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="34" available={isAvailable(id, 34)}
                      active={isActive(id, 34)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="33" available={isAvailable(id, 33)}
                      active={isActive(id, 33)}/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="39" reverse={true}
                      available={isAvailable(id, 39)}
                      active={isActive(id, 39)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="40" reverse={true}
                      available={isAvailable(id, 40)}
                      active={isActive(id, 40)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="36" reverse={true}
                      available={isAvailable(id, 36)}
                      active={isActive(id, 36)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="35" reverse={true}
                      available={isAvailable(id, 35)}
                      active={isActive(id, 35)}/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="46" available={isAvailable(id, 46)}
                      active={isActive(id, 46)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="45" available={isAvailable(id, 45)}
                      active={isActive(id, 45)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="42" available={isAvailable(id, 42)}
                      active={isActive(id, 42)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="41" available={isAvailable(id, 41)}
                      active={isActive(id, 41)}/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="47" reverse={true}
                      available={isAvailable(id, 47)}
                      active={isActive(id, 47)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="48" reverse={true}
                      available={isAvailable(id, 48)}
                      active={isActive(id, 48)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="44" reverse={true}
                      available={isAvailable(id, 44)}
                      active={isActive(id, 44)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="43" reverse={true}
                      available={isAvailable(id, 43)}
                      active={isActive(id, 43)}/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="50" available={isAvailable(id, 50)}
                      active={isActive(id, 50)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="49" available={isAvailable(id, 49)}
                      active={isActive(id, 49)}/>
                <EmptySeat/>
                <EmptySeat/>
            </div>
            <div className="table-row">
                <div className="table"/>
                <div className="empty-table"/>
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="51" reverse={true}
                      available={isAvailable(id, 51)} active={isActive(id, 51)}/>
                <Seat carriage={id} announcedNumber={announcedNumber} number="52" reverse={true}
                      available={isAvailable(id, 52)} active={isActive(id, 52)}/>
                <EmptySeat/>
                <EmptySeat/>
            </div>
            <div className="vestibule">
                <div className="wc-bottom-right">
                    WC
                </div>
            </div>
        </div>
    )
}
