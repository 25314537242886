import {
    SET_TOTAL_PRICE,
} from "./types";

export const setTotalPrice = (price) => async (dispatch) => {
    try {
        dispatch({
            type: SET_TOTAL_PRICE,
            payload: price,
        });
    } catch (err) {
        console.log(err);
    }
};
