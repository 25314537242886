import {
    INCREMENT_ADULTS,
    DECREMENT_ADULTS,
    INCREMENT_INFANTS,
    INCREMENT_CHILDREN,
    INCREMENT_YOUTH, DECREMENT_INFANTS, DECREMENT_CHILDREN, DECREMENT_YOUTH,
} from "./types";

export const addTraveler = (type) => async (dispatch) => {
    try {
        switch(type) {
            case 'adult':
                dispatch({
                    type: INCREMENT_ADULTS,
                });
                break;
            case 'infant':
                dispatch({
                    type: INCREMENT_INFANTS,
                });
                break;
            case 'child':
                dispatch({
                    type: INCREMENT_CHILDREN,
                });
                break;
            case 'youth':
                dispatch({
                    type: INCREMENT_YOUTH,
                });
                break;
        }
    } catch (err) {
        console.log(err);
    }
};

export const removeTraveler = (type) => async (dispatch) => {
    try {
        switch(type) {
            case 'adult':
                dispatch({
                    type: DECREMENT_ADULTS,
                });
                break;
            case 'infant':
                dispatch({
                    type: DECREMENT_INFANTS,
                });
                break;
            case 'child':
                dispatch({
                    type: DECREMENT_CHILDREN,
                });
                break;
            case 'youth':
                dispatch({
                    type: DECREMENT_YOUTH,
                });
                break;
        }
    } catch (err) {
        console.log(err);
    }
};
