import React, {useState} from "react";
import {useSelector} from "react-redux";

import Spacer from "../../components/SeatSpacer";
import Seat from "../../components/Seat";
import EmptySeat from "../../components/EmptySeat";
import CompartmentLabel from "../../components/CompartmentLabel";

export default function B5(props) {
    const { id, reservedSeats, announcedNumber, number } = props;

    const activeSeats = useSelector(state => state.activeSeats);

    const isAvailable = (carriageId, seatNumber) => {
        return !reservedSeats.some(seat => {
            return (seat.carriageId === carriageId && seat.number === seatNumber)
        });
    };

    const isActive = (carriage, number) => {
        return Object.keys(activeSeats).some(seat => (seat.carriage === carriage && seat.seat === number))
    };

    return (
        <div className="wagon-box">
            <div className="vestibule">
                <div className="wc-top-left">
                    WC
                </div>
            </div>
            <div className="wall-compartment-top">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-staff">
                Personal
            </div>
            <div className="wall-compartment-top">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="15" available={isAvailable(id, 15)} active={isActive(id, 15)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="13" available={isAvailable(id, 13)} active={isActive(id, 13)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="11" available={isAvailable(id, 11)} active={isActive(id, 11)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide " />
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="16" reverse={true} available={isAvailable(id, 16)} active={isActive(id, 16)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="14" reverse={true} available={isAvailable(id, 14)} active={isActive(id, 14)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="12" reverse={true} available={isAvailable(id, 12)} active={isActive(id, 12)} compartmentBottom={true} />
                <div className="bottom-wall-right" />
            </div>
            <div className="wall-compartment-top">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="25" available={isAvailable(id, 25)} active={isActive(id, 25)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="23" available={isAvailable(id, 23)} active={isActive(id, 23)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="21" available={isAvailable(id, 21)} active={isActive(id, 21)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide " />
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="26" reverse={true} available={isAvailable(id, 26)} active={isActive(id, 26)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="24" reverse={true} available={isAvailable(id, 24)} active={isActive(id, 24)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="22" reverse={true} available={isAvailable(id, 22)} active={isActive(id, 22)} compartmentBottom={true} />
                <div className="bottom-wall-right" />
            </div>
            <div className="wall-compartment-top">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="35" available={isAvailable(id, 35)} active={isActive(id, 35)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="33" available={isAvailable(id, 33)} active={isActive(id, 33)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="31" available={isAvailable(id, 31)} active={isActive(id, 31)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide " />
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="36" reverse={true} available={isAvailable(id, 36)} active={isActive(id, 36)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="34" reverse={true} available={isAvailable(id, 34)} active={isActive(id, 34)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="32" reverse={true} available={isAvailable(id, 32)} active={isActive(id, 32)} compartmentBottom={true} />
                <div className="bottom-wall-right" />
            </div>
            <div className="wall-compartment-top">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="45" available={isAvailable(id, 45)} active={isActive(id, 45)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="43" available={isAvailable(id, 43)} active={isActive(id, 43)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="41" available={isAvailable(id, 41)} active={isActive(id, 41)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide " />
            <div className="compartment-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="46" reverse={true} available={isAvailable(id, 46)} active={isActive(id, 46)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="44" reverse={true} available={isAvailable(id, 44)} active={isActive(id, 44)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="42" reverse={true} available={isAvailable(id, 42)} active={isActive(id, 42)} compartmentBottom={true} />
                <div className="bottom-wall-right" />
            </div>
            <div className="wall-compartment-top">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="55" available={isAvailable(id, 55)} active={isActive(id, 55)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="57" available={isAvailable(id, 57)} active={isActive(id, 57)} />
            </div>
            <div className="table-row">
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="56" reverse={true} available={isAvailable(id, 56)} active={isActive(id, 56)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="58" reverse={true} available={isAvailable(id, 58)} active={isActive(id, 58)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="53" available={isAvailable(id, 53)} active={isActive(id, 53)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="51" available={isAvailable(id, 51)} active={isActive(id, 51)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="65" reverse={true} available={isAvailable(id, 65)} active={isActive(id, 65)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="67" reverse={true} available={isAvailable(id, 67)} active={isActive(id, 67)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="63" available={isAvailable(id, 63)} active={isActive(id, 63)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="61" available={isAvailable(id, 61)} active={isActive(id, 61)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="75" reverse={true} available={isAvailable(id, 75)} active={isActive(id, 75)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="77" reverse={true} available={isAvailable(id, 77)} active={isActive(id, 77)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="73" available={isAvailable(id, 73)} active={isActive(id, 73)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="71" available={isAvailable(id, 71)} active={isActive(id, 71)} />
            </div>
            <div className="table-row">
                <div className="empty-table" />
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="76" reverse={true} available={isAvailable(id, 76)} active={isActive(id, 76)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="78" reverse={true} available={isAvailable(id, 78)} active={isActive(id, 78)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="74" reverse={true} available={isAvailable(id, 74)} active={isActive(id, 74)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="72" reverse={true} available={isAvailable(id, 72)} active={isActive(id, 72)} />
            </div>
            <div className="wall-row">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="85" available={isAvailable(id, 85)} active={isActive(id, 85)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="87" available={isAvailable(id, 87)} active={isActive(id, 87)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="83" available={isAvailable(id, 83)} active={isActive(id, 83)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="81" available={isAvailable(id, 81)} active={isActive(id, 81)} />
            </div>
            <div className="table-row">
                <div className="empty-table" />
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="95" available={isAvailable(id, 95)} active={isActive(id, 95)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="97" available={isAvailable(id, 97)} active={isActive(id, 97)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="84" reverse={true} available={isAvailable(id, 84)} active={isActive(id, 84)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="82" reverse={true} available={isAvailable(id, 82)} active={isActive(id, 82)} />
            </div>
            <div className="table-row">
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="96" reverse={true} available={isAvailable(id, 96)} active={isActive(id, 96)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="98" reverse={true} available={isAvailable(id, 98)} active={isActive(id, 98)} />
            </div>
            <div className="vestibule">
                <div className="wc-top-right">
                    WC
                </div>
            </div>
        </div>
    )
}
