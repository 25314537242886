import React, {useEffect} from "react";
import {CheckIcon} from '@heroicons/react/solid'
import {useDispatch, useSelector } from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import {setStep} from "../actions/steps";

const Steps = () => {
    const {id} = useParams();
    const steps = useSelector(state => state.steps);
    const dispatch = useDispatch();
    const history = useHistory();
    const trains = useSelector(state => state.trains);

    useEffect(() => {
        return history.listen((location) => {
            switch(true) {
                case location.pathname.includes('station'):
                    return dispatch(setStep(2));
                case location.pathname.includes('travelers'):
                    return dispatch(setStep(3));
                case location.pathname.includes('seats'):
                    return dispatch(setStep(4));
                case location.pathname.includes('book'):
                    return dispatch(setStep(5));
                case location.pathname.includes('confirmation'):
                    return dispatch(setStep(6));
                default:
                    return;
            }
        })
    },[history])

    if (trains.data && trains.data.length === 0) {
        return null;
    }

    return (
        <nav aria-label="Progress" className="pb-8">
            <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                {steps && steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative md:flex-1 md:flex">
                        {step.status === 'complete' ? (
                            <div href={step.href} className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
                            </div>
                        ) : step.status === 'current' ? (
                            <div className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                            </div>
                        ) : (
                            <div href={step.href} className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
                            </div>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                                    <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default Steps;
