import React, { useState, useEffect } from "react";
import TrainService from "../services/TrainService";
import Wagon from "./Wagon";
import ReservationInfo from "./ReservationInfo";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const Train = (props) => {
    const { id } = useParams();

    const initialTrainState = {
        id: null,
        carriages: [],
        name: "",
        description: "",
        number: ""
    };
    const [currentTrain, setCurrentTrain] = useState(initialTrainState);

    const getTrain = id => {
        TrainService.get(id)
            .then(response => {
                setCurrentTrain(response.data.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getTrain(props.match.params.id);
    }, [props.match.params.id]);

    const { seats } = useSelector(state => state.activeSeats);
    const { travelers } = useSelector(state => state);

    return (
        <>
            <div className="bg-white overflow-hidden shadow rounded-lg">
                {currentTrain && (
                    <>
                        <div className="px-4 pt-5 sm:pt-6">
                            <h3 className="text-center text-base font-semibold text-indigo-600 tracking-wide uppercase">Välj sittplats</h3>
                            <div className="content">
                                {currentTrain.carriages &&
                                currentTrain.carriages.map((carriage, index) => (
                                        <React.Fragment key={index}>
                                            <Wagon key={carriage.id} id={carriage.id} series={carriage.class} number={carriage.number} announcedNumber={carriage.announcedNumber} reservedSeats={currentTrain.reservedSeats} />
                                        </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="px-4 pb-2 flex items-center justify-center w-full">
                            <div className="scroll-hint">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5 text-gray-400" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
                                </svg>
                            </div>
                            <div className="text-sm text-gray-500">Scrolla för fler vagnar/platser</div>
                            <div className="scroll-hint">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </div>
                        </div>
                        <div className="px-4 pb-2 sm:pb-2 flex items-center justify-center w-full">
                            <div className="w-6 h-6 mr-2 first-class">
                            </div>
                            <div className="text-sm text-gray-500">Platser i 1-klass visas mot gul bakgrund</div>
                        </div>
                    </>
                )}

                <div className="mt-6">
                    {travelers && (
                        <>
                            {seats.length === travelers['total'] && seats.length > 0 && (
                                <Link to={"/trains/" + id + '/book'}
                                      className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Fortsätt
                                </Link>
                            )}
                            {seats.length !== travelers['total'] && (
                                <div className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Fortsätt
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="bg-gray-50 overflow-hidden shadow rounded-lg">
                <ReservationInfo />
            </div>
        </>
    );
};

export default Train;
