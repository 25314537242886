import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { useForm, useFormContext } from "react-hook-form";
import {
    createTicket,
} from "../../actions/tickets";
import {
    PaymentRequestButtonElement,
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import StripeService from "../../services/StripeService";

import Spinner from "../Spinner.js";

export default function CheckoutForm(props) {
    const [stripeError, setStripeError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const {handleSubmit, formState: {errors}} = useFormContext();
    const dispatch = useDispatch();

    const train = useSelector(state => state.trains.currentTrain);
    const station = useSelector(state => state.activeStation);
    const travelers = useSelector(state => state.travelers);
    const { seats } = useSelector(state => state.activeSeats);
    const price = useSelector(state => state.price);
    let history = useHistory();

    const handleChange = async (event) => {
        setDisabled(event.empty);
        setStripeError(event.error ? event.error.message : "");
    };

    const submitStripePayment = async (data, ev) => {
        const { travelers: [passenger], email } = data;
        const { firstName, lastName } = passenger;
        setProcessing(true);

        const res = await StripeService.createPaymentIntent(JSON.stringify({train, station, travelers, seats, price}));

        if (! res?.clientSecret) {
            setStripeError('Payment failed, something went wrong in the backend. Please contact us or try again.');
            return;
        }

        const payload = await stripe.confirmCardPayment(res.clientSecret, {
            receipt_email: email,
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    email: email,
                    name: firstName + ' ' + lastName
                }
            }
        });
        if (payload.error) {
            setStripeError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            dispatch(createTicket({train, station, seats, travelers: data}));
            setStripeError(null);
            setProcessing(false);
            history.push('/confirmation');
        }
    };

    const onSubmit = (data, e) => {
        e.preventDefault();
        submitStripePayment(data, e);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
            <CardElement id="card-element" className="py-4 my-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" onChange={handleChange} />
            <button
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={processing || disabled}
                id="submit"
            >
        <span id="button-text">
          {processing ? (
              <Spinner />
          ) : (
              "Betala"
          )}
        </span>
            </button>
            {stripeError && (
                <div className="card-error" role="alert">
                    {stripeError}
                </div>
            )}
        </form>
    );
}
