import {
    APPLY_DISCOUNT,
} from "./types";

import CouponService from "../services/CouponService";

export const validateCoupon = (data) => async (dispatch) => {
    try {
        const res = await CouponService.verifyCoupon(data);
        const { coupon: { code, description, discount_amount: amount, is_fixed: isFixed }, error } = res;
        dispatch({
            type: APPLY_DISCOUNT,
            payload: { code, description, amount, isFixed, error },
        });
    } catch (err) {
        console.log(err);
    }
};
