import {Link, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {
    removeTraveler,
    addTraveler
} from "../actions/travelers";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentTrain} from "../actions/trains";

export default function Travelers() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const travelers = useSelector(state => state.travelers);
    const { currentTrain: train } = useSelector(state => state.trains);

    const checkSeatLimit = event => {
        if (travelers.total > train.totalAvailableSeats || travelers.total === 0) {
            event.preventDefault()
        }
    };

    useEffect(() => {
        dispatch(setCurrentTrain(id));
    }, []);

    return (
        <>
            {train && (
            <div>
                <div className="flow-root mt-6">
                    <h3 className="text-center text-base font-semibold text-indigo-600 tracking-wide uppercase">Antal resenärer</h3>
                    <ul className="-my-5 divide-y divide-gray-200">
                        {Object.keys(travelers).map((index) => (
                            index !== 'total' && (
                                <>
                                    {! train.isFullPriceOnly && (
                                        <li key={index} className="py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-900 truncate">{travelers[index].name}</p>
                                                    <p className="text-sm text-gray-500 truncate">{travelers[index].description}</p>
                                                </div>
                                                <div>
                                                    <div className="custom-number-input h-10 w-32">
                                                        <div
                                                            className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                                                            <button onClick={() => dispatch(removeTraveler(index))}
                                                                    className="shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-l text-gray-700 bg-white hover:bg-gray-50 cursor-pointer outline-none">
                                                                <span className="m-auto text-2xl font-thin">−</span>
                                                            </button>
                                                            <input type="number"
                                                                   className="outline-none focus:outline-none text-center w-full border-gray-300 bg-white font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                                                   name="custom-input-number" value={travelers[index].count}></input>
                                                            <button onClick={() => dispatch(addTraveler(index))}
                                                                    className="shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-r text-gray-700 bg-white hover:bg-gray-50 cursor-pointer">
                                                                <span className="m-auto text-2xl font-thin">+</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {train.isFullPriceOnly && index === 'adult' && (
                                        <li key={index} className="py-4">
                                            <div className="flex items-center space-x-4">
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-900 truncate">Resenär</p>
                                                    <p className="text-sm text-gray-500 truncate">Vuxen/Ungdom/Barn</p>
                                                </div>
                                                <div>
                                                    <div className="custom-number-input h-10 w-32">
                                                        <div
                                                            className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                                                            <button onClick={() => dispatch(removeTraveler(index))}
                                                                    className="shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-l text-gray-700 bg-white hover:bg-gray-50 cursor-pointer outline-none">
                                                                <span className="m-auto text-2xl font-thin">−</span>
                                                            </button>
                                                            <input type="number"
                                                                   className="outline-none focus:outline-none text-center w-full border-gray-300 bg-white font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                                                   name="custom-input-number" value={travelers[index].count}></input>
                                                            <button onClick={() => dispatch(addTraveler(index))}
                                                                    className="shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-r text-gray-700 bg-white hover:bg-gray-50 cursor-pointer">
                                                                <span className="m-auto text-2xl font-thin">+</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </>
                            )
                        ))}
                    </ul>
                </div>
                {travelers.total > train.totalAvailableSeats && (<div className="mt-6 justify-center items-center">
                <span className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md bg-red-100 text-red-800">
                    Vi har inte så här många platser lediga på det här tåget. Kontakta oss för mer information.
                </span>
                </div>)}
                <div className="mt-6">
                    {travelers.total <= train.totalAvailableSeats && travelers.total > 0 && (
                        <Link to={"/trains/" + id + '/seats'}
                              onClick={checkSeatLimit}
                              className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Fortsätt
                        </Link>
                    )}
                    {! travelers.total <= train.totalAvailableSeats && travelers.total <= 0 && (
                        <div className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Fortsätt
                        </div>
                    )}
                </div>
            </div>
            )}
        </>
    )
}
