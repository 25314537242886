import {
    SET_ACTIVE_STATION,
} from "./types";

import TrainService from "../services/TrainService";

export const setCurrentStation = (station) => async (dispatch) => {
    try {
        dispatch({
            type: SET_ACTIVE_STATION,
            payload: station,
        });
    } catch (err) {
        console.log(err);
    }
};
