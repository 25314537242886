import React, {useState} from "react";
import {useSelector} from "react-redux";

import Seat from "../../components/Seat";
import EmptySeat from "../../components/EmptySeat";

export default function A2(props) {
    const { id, reservedSeats, announcedNumber, number } = props;

    const activeSeats = useSelector(state => state.activeSeats);

    const isAvailable = (carriageId, seatNumber) => {
        return !reservedSeats.some(seat => {
            return (seat.carriageId === carriageId && seat.number === seatNumber)
        });
    };

    const isActive = (carriage, number) => {
        return Object.keys(activeSeats).some(seat => (seat.carriage === carriage && seat.seat === number))
    };

    return (
        <div className="wagon-box first-class">
            <div className="vestibule">
                <div className="wc-bottom-left">
                    WC
                </div>
            </div>
            <div className="seat-row">
                <EmptySeat />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="2" firstClass={true} available={isAvailable(id, 2)} active={isActive(id, 2)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="1" firstClass={true} available={isAvailable(id, 1)} active={isActive(id, 1)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="6" firstClass={true} available={isAvailable(id, 6)} active={isActive(id, 6)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="5" firstClass={true} available={isAvailable(id, 5)} active={isActive(id, 5)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="4" firstClass={true} available={isAvailable(id, 4)} active={isActive(id, 4)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="9" firstClass={true} available={isAvailable(id, 9)} active={isActive(id, 9)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="8" firstClass={true} available={isAvailable(id, 8)} active={isActive(id, 8)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="7" firstClass={true} available={isAvailable(id, 7)} active={isActive(id, 7)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="12" firstClass={true} available={isAvailable(id, 12)} active={isActive(id, 12)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="11" firstClass={true} available={isAvailable(id, 11)} active={isActive(id, 11)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="10" firstClass={true} available={isAvailable(id, 10)} active={isActive(id, 10)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="15" firstClass={true} available={isAvailable(id, 15)} active={isActive(id, 15)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="14" firstClass={true} available={isAvailable(id, 14)} active={isActive(id, 14)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="13" firstClass={true} available={isAvailable(id, 13)} active={isActive(id, 13)} />
            </div>
            <div className="table-row">
                <div className="table" />
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="18" reverse={true} firstClass={true} available={isAvailable(id, 18)} active={isActive(id, 18)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="17" reverse={true} firstClass={true} available={isAvailable(id, 17)} active={isActive(id, 17)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="16" reverse={true} firstClass={true} available={isAvailable(id, 16)} active={isActive(id, 16)} />
            </div>
            <div className="wall-row">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="21" firstClass={true} available={isAvailable(id, 21)} active={isActive(id, 21)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="20" firstClass={true} available={isAvailable(id, 20)} active={isActive(id, 20)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="19" firstClass={true} available={isAvailable(id, 19)} active={isActive(id, 19)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="24" firstClass={true} available={isAvailable(id, 24)} active={isActive(id, 24)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="23" firstClass={true} available={isAvailable(id, 23)} active={isActive(id, 23)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="22" firstClass={true} available={isAvailable(id, 22)} active={isActive(id, 22)} />
            </div>
            <div className="table-row">
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="27" reverse={true} firstClass={true} available={isAvailable(id, 27)} active={isActive(id, 27)} />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="26" firstClass={true} available={isAvailable(id, 26)} active={isActive(id, 26)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="25" firstClass={true} available={isAvailable(id, 25)} active={isActive(id, 25)} />
            </div>
            <div className="table-row">
                <div className="empty-table" />
                <div className="table" />
            </div>
            <div className="seat-row">
                <EmptySeat />
                <EmptySeat />
                <Seat carriage={id} announcedNumber={announcedNumber} number="29" reverse={true} firstClass={true} available={isAvailable(id, 29)} active={isActive(id, 29)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="28" reverse={true} firstClass={true} available={isAvailable(id, 28)} active={isActive(id, 28)} />
            </div>
            <div className="wall-compartment">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row">
                <div className="top-wall-left" />
                <Seat carriage={id} announcedNumber={announcedNumber} number="33" firstClass={true} available={isAvailable(id, 33)} active={isActive(id, 33)} compartmentTop={true} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="32" firstClass={true} available={isAvailable(id, 32)} active={isActive(id, 32)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="31" firstClass={true} available={isAvailable(id, 31)} active={isActive(id, 31)} />
            </div>
            <div className="wide" />
            <div className="compartment-row">
                <div className="top-wall-right" />
                <Seat carriage={id} announcedNumber={announcedNumber} number="36" reverse={true} firstClass={true} available={isAvailable(id, 36)} active={isActive(id, 36)} compartmentTop={true} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="35" reverse={true} firstClass={true} available={isAvailable(id, 35)} active={isActive(id, 35)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="34" reverse={true} firstClass={true} available={isAvailable(id, 34)} active={isActive(id, 34)} />
            </div>
            <div className="wall-compartment">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row">
                <div className="top-wall-left" />
                <Seat carriage={id} announcedNumber={announcedNumber} number="42" firstClass={true} available={isAvailable(id, 42)} active={isActive(id, 42)} compartmentTop={true} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="41" firstClass={true} available={isAvailable(id, 41)} active={isActive(id, 41)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="40" firstClass={true} available={isAvailable(id, 40)} active={isActive(id, 40)} />
            </div>
            <div className="wide" />
            <div className="compartment-row">
                <div className="top-wall-right" />
                <Seat carriage={id} announcedNumber={announcedNumber} number="45" reverse={true} firstClass={true} available={isAvailable(id, 45)} active={isActive(id, 45)} compartmentTop={true} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="44" reverse={true} firstClass={true} available={isAvailable(id, 44)} active={isActive(id, 44)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="43" reverse={true} firstClass={true} available={isAvailable(id, 43)} active={isActive(id, 43)} />
            </div>
            <div className="wall-compartment">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="vestibule">
                <div className="wc-bottom-right">
                    WC
                </div>
            </div>
        </div>
    )
}
