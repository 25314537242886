import {
    RETRIEVE_STEPS,
    SET_STEP,
} from "./types";

export const retrieveSteps = () => async (dispatch) => {
    try {
        dispatch({
            type: RETRIEVE_STEPS,
        });
    } catch (err) {
        console.log(err);
    }
};

export const setStep = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SET_STEP,
            currentStep: id,
        });
    } catch (err) {
        console.log(err);
    }
};
