import {useParams} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import ReservationInfo from "./ReservationInfo";
import PaymentMethods from "./PaymentMethods";
import DietPreference from "./DietPreference";
import classNames from "classnames";
import {FormProvider, useForm} from "react-hook-form";

export default function Travelers() {
    const {id} = useParams();
    const travelers = useSelector(state => state.travelers);
    const { adult, youth, child, infant } = travelers;
    const { currentTrain: train } = useSelector(state => state.trains);
    const methods = useForm();
    const {register, formState, formState: { errors }, handleSubmit} = methods;
    let passengerListIndex = 0;

    return (
        <>
            <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-center text-base font-semibold text-indigo-600 tracking-wide uppercase">Betala</h3>
                    <FormProvider {...methods} >
                        <div className="space-y-6">
                            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Resenärer</h3>
                                        <p className="mt-1 text-sm text-gray-500">Bokningsbekräftelse, kvitto och trafikinformation skickas till den första resenären i listan, som blir kontaktperson.</p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <form>
                                            {[...Array(adult.count)].map((index) => {
                                                passengerListIndex++;
                                                return (
                                                    <div key={passengerListIndex-1}>
                                                        <div className={classNames("relative", {
                                                            "mt-6": index !== 0,
                                                            "mb-6": index !== travelers.total
                                                        })}>
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                        <span
                                                            className="px-2 bg-white text-sm text-gray-500">Resenär {passengerListIndex} {!train.isFullPriceOnly && (<>{travelers['adult'].name} ({travelers['adult'].description})</>)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.firstName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Förnamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.firstName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="given-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.firstName && (
                                                                    <p className="mt-2 text-sm text-red-600">Förnamn
                                                                        måste anges.</p>)}
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.lastName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Efternamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.lastName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="family-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.lastName && (
                                                                    <p className="mt-2 text-sm text-red-600">Efternamn
                                                                        måste anges.</p>)}
                                                            </div>

                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="email"
                                                                           className="block text-sm font-medium text-gray-700">
                                                                        E-postadress
                                                                    </label>
                                                                    <input
                                                                        {...register(`email`, {required: true})}
                                                                        type="text"
                                                                        autoComplete="email"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                    />
                                                                    {errors.email && (
                                                                        <p className="mt-2 text-sm text-red-600">E-postadress
                                                                            måste anges.</p>)}
                                                                </div>)}
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700">
                                                                        Telefon
                                                                    </label>
                                                                    <input
                                                                        {...register('phone', {required: true})}
                                                                        type="text"
                                                                        autoComplete="phone"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                        />
                                                                    {errors.phone && (
                                                                        <p className="mt-2 text-sm text-red-600">Telefon
                                                                            måste anges.</p>)}
                                                                </div>
                                                            )}
                                                            <div className="col-span-12 sm:col-span-4">
                                                                <DietPreference travelerIndex={passengerListIndex-1} />
                                                            </div>
                                                        </div>
                                                        <input
                                                            {...register(`travelers.${passengerListIndex-1}.description`, {required: true})}
                                                            type="hidden"
                                                            value={travelers['adult'].description}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            {[...Array(youth.count)].map((index) => {
                                                passengerListIndex++;
                                                return (
                                                    <div key={passengerListIndex-1}>
                                                        <div className={classNames("relative", {
                                                            "mt-6": index !== 0,
                                                            "mb-6": index !== travelers.total
                                                        })}>
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                        <span
                                                            className="px-2 bg-white text-sm text-gray-500">Resenär {passengerListIndex} {travelers['youth'].name} ({travelers['youth'].description})</span>
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.firstName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Förnamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.firstName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="given-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.firstName && (
                                                                    <p className="mt-2 text-sm text-red-600">Förnamn
                                                                        måste anges.</p>)}
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.lastName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Efternamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.lastName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="family-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.lastName && (
                                                                    <p className="mt-2 text-sm text-red-600">Efternamn
                                                                        måste anges.</p>)}
                                                            </div>
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="email"
                                                                           className="block text-sm font-medium text-gray-700">
                                                                        E-postadress
                                                                    </label>
                                                                    <input
                                                                        {...register(`email`, {required: true})}
                                                                        type="text"
                                                                        autoComplete="email"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                    />
                                                                    {errors.email && (
                                                                        <p className="mt-2 text-sm text-red-600">E-postadress
                                                                            måste anges.</p>)}
                                                                </div>)}
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700">
                                                                        Telefon
                                                                    </label>
                                                                    <input
                                                                        {...register('phone', {required: true})}
                                                                        type="text"
                                                                        autoComplete="phone"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                        />
                                                                    {errors.phone && (
                                                                        <p className="mt-2 text-sm text-red-600">Telefon
                                                                            måste anges.</p>)}
                                                                </div>
                                                            )}
                                                            <div className="col-span-12 sm:col-span-4">
                                                                <DietPreference travelerIndex={passengerListIndex-1} />
                                                            </div>
                                                        </div>
                                                        <input
                                                            {...register(`travelers.${passengerListIndex-1}.description`, {required: true})}
                                                            type="hidden"
                                                            value={travelers['youth'].description}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            {[...Array(child.count)].map((index) => {
                                                passengerListIndex++;
                                                return (
                                                    <div key={passengerListIndex}>
                                                        <div className={classNames("relative", {
                                                            "mt-6": index !== 0,
                                                            "mb-6": index !== travelers.total
                                                        })}>
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                        <span
                                                            className="px-2 bg-white text-sm text-gray-500">Resenär {passengerListIndex} {travelers['child'].name} ({travelers['child'].description})</span>
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.firstName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Förnamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.firstName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="given-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.firstName && (
                                                                    <p className="mt-2 text-sm text-red-600">Förnamn
                                                                        måste anges.</p>)}
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.lastName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Efternamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.lastName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="family-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.lastName && (
                                                                    <p className="mt-2 text-sm text-red-600">Efternamn
                                                                        måste anges.</p>)}
                                                            </div>
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="email"
                                                                           className="block text-sm font-medium text-gray-700">
                                                                        E-postadress
                                                                    </label>
                                                                    <input
                                                                        {...register(`email`, {required: true})}
                                                                        type="text"
                                                                        autoComplete="email"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                    />
                                                                    {errors.email && (
                                                                        <p className="mt-2 text-sm text-red-600">E-postadress
                                                                            måste anges.</p>)}
                                                                </div>)}
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700">
                                                                        Telefon
                                                                    </label>
                                                                    <input
                                                                        {...register('phone', {required: true})}
                                                                        type="text"
                                                                        autoComplete="phone"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                        />
                                                                    {errors.phone && (
                                                                        <p className="mt-2 text-sm text-red-600">Telefon
                                                                            måste anges.</p>)}
                                                                </div>
                                                            )}
                                                            <div className="col-span-12 sm:col-span-4">
                                                                <DietPreference travelerIndex={passengerListIndex-1} />
                                                            </div>
                                                        </div>
                                                        <input
                                                            {...register(`travelers.${passengerListIndex-1}.description`, {required: true})}
                                                            type="hidden"
                                                            value={travelers['child'].description}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            {[...Array(infant.count)].map((index) => {
                                                passengerListIndex++;
                                                return (
                                                    <div key={passengerListIndex}>
                                                        <div className={classNames("relative", {
                                                            "mt-6": index !== 0,
                                                            "mb-6": index !== travelers.total
                                                        })}>
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                        <span
                                                            className="px-2 bg-white text-sm text-gray-500">Resenär {passengerListIndex} {travelers['infant'].name} ({travelers['infant'].description})</span>
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-6 gap-6">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.firstName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Förnamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.firstName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="given-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.firstName && (
                                                                    <p className="mt-2 text-sm text-red-600">Förnamn
                                                                        måste anges.</p>)}
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor={`travelers.${passengerListIndex-1}.lastName`}
                                                                       className="block text-sm font-medium text-gray-700">
                                                                    Efternamn
                                                                </label>
                                                                <input
                                                                    {...register(`travelers.${passengerListIndex-1}.lastName`, {required: true})}
                                                                    type="text"
                                                                    autoComplete="family-name"
                                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                />
                                                                {errors?.travelers?.[passengerListIndex-1]?.lastName && (
                                                                    <p className="mt-2 text-sm text-red-600">Efternamn
                                                                        måste anges.</p>)}
                                                            </div>
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="email"
                                                                           className="block text-sm font-medium text-gray-700">
                                                                        E-postadress
                                                                    </label>
                                                                    <input
                                                                        {...register(`email`, {required: true})}
                                                                        type="text"
                                                                        autoComplete="email"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                    />
                                                                    {errors.email && (
                                                                        <p className="mt-2 text-sm text-red-600">E-postadress
                                                                            måste anges.</p>)}
                                                                </div>)}
                                                            {passengerListIndex === 1 && (
                                                                <div className="col-span-6 sm:col-span-3">
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700">
                                                                        Telefon
                                                                    </label>
                                                                    <input
                                                                        {...register('phone', {required: true})}
                                                                        type="text"
                                                                        autoComplete="phone"
                                                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                                        />
                                                                    {errors.phone && (
                                                                        <p className="mt-2 text-sm text-red-600">Telefon
                                                                            måste anges.</p>)}
                                                                </div>
                                                            )}
                                                            <div className="col-span-12 sm:col-span-4">
                                                                <DietPreference travelerIndex={passengerListIndex-1} />
                                                            </div>
                                                        </div>
                                                        <input
                                                            {...register(`travelers.${passengerListIndex-1}.description`, {required: true})}
                                                            type="hidden"
                                                            value={travelers['infant'].description}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Sammanfattning</h3>
                                        <p className="mt-1 text-sm text-gray-500">Granska att din bokning stämmer vad gäller resmål, resenärer och station.</p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <ReservationInfo checkout={true} />
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Betalsätt</h3>
                                        <p className="mt-1 text-sm text-gray-500">Betala via säkert med kort online. Ange ev. rabattkod innan du genomför korbetalningen.</p>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <PaymentMethods />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormProvider>
                </div>
            </div>
        </>
    )
}
