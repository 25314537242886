import {
    INCREMENT_ADULTS,
    DECREMENT_ADULTS,
    INCREMENT_INFANTS,
    DECREMENT_INFANTS,
    INCREMENT_CHILDREN,
    DECREMENT_CHILDREN,
    INCREMENT_YOUTH,
    DECREMENT_YOUTH,
} from "../actions/types";

const initialState = {
    adult: {
        name: "Vuxen",
        description: '18+',
        count: 0,
    },
    infant: {
        name: "Barn",
        description: '0-6 år',
        count: 0
    },
    child: {
        name: "Barn",
        description: '7-12 år',
        count: 0
    },
    youth: {
        name: "Ungdom",
        description: '13-17 år',
        count: 0
    },
    total: 0
};

function travelerReducer(state = initialState, action) {
    switch(action.type) {
        case 'INCREMENT_ADULTS':
            return {
                ...state,
                total: state.total + 1,
                adult: {
                    ...state.adult,
                    count: state.adult.count + 1
                }
            };
        case 'DECREMENT_ADULTS':
            return {
                ...state,
                total: state.adult.count - 1 >= 0 ? state.total - 1 : state.total,
                adult: {
                    ...state.adult,
                    count: state.adult.count - 1 >= 0 ? state.adult.count - 1 : 0
                }
            };
        case 'INCREMENT_INFANTS':
            return {
                ...state,
                total: state.total + 1,
                infant: {
                    ...state.infant,
                    count: state.infant.count + 1
                }
            };
        case 'DECREMENT_INFANTS':
            return {
                ...state,
                total: state.infant.count - 1 >= 0 ? state.total - 1 : state.total,
                infant: {
                    ...state.infant,
                    count: state.infant.count - 1 >= 0 ? state.infant.count - 1 : 0
                }
            };
        case 'INCREMENT_CHILDREN':
            return {
                ...state,
                total: state.total + 1,
                child: {
                    ...state.child,
                    count: state.child.count + 1
                }
            };
        case 'DECREMENT_CHILDREN':
            return {
                ...state,
                total: state.child.count - 1 >= 0 ? state.total - 1 : state.total,
                child: {
                    ...state.child,
                    count: state.child.count - 1 >= 0 ? state.child.count - 1 : 0
                }
            };
        case 'INCREMENT_YOUTH':
            return {
                ...state,
                total: state.total + 1,
                youth: {
                    ...state.youth,
                    count: state.youth.count + 1
                }
            };
        case 'DECREMENT_YOUTH':
            return {
                ...state,
                total: state.youth.count - 1 >= 0 ? state.total - 1 : state.total,
                youth: {
                    ...state.youth,
                    count: state.youth.count - 1 >= 0 ? state.youth.count - 1 : 0
                }
            };
        default:
            return state;
    }
};

export default travelerReducer;
