import http from "../http-common";

const createPaymentIntent = data => {
    return http.post("/stripe/paymentintent", data)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        });
};

const StripeService = {
    createPaymentIntent,
};

export default StripeService;
