// Trains
export const RETRIEVE_TRAINS = "RETRIEVE_TRAINS";
export const SET_ACTIVE_TRAIN = "SET_ACTIVE_TRAIN";

// Travelers
export const INCREMENT_ADULTS = "INCREMENT_ADULTS";
export const DECREMENT_ADULTS = "DECREMENT_ADULTS";
export const INCREMENT_INFANTS = "INCREMENT_INFANTS";
export const DECREMENT_INFANTS = "DECREMENT_INFANTS";
export const INCREMENT_CHILDREN = "INCREMENT_CHILDREN";
export const DECREMENT_CHILDREN = "DECREMENT_CHILDREN";
export const INCREMENT_YOUTH = "INCREMENT_YOUTH";
export const DECREMENT_YOUTH = "DECREMENT_YOUTH";

// Seats
export const ADD_SEAT = "ADD_SEAT";
export const REMOVE_SEAT = "REMOVE_SEAT";

// Steps
export const RETRIEVE_STEPS = "RETRIEVE_STEPS";
export const SET_STEP = "SET_STEP";

// Stations
export const SET_ACTIVE_STATION = "SET_ACTIVE_STATION";

// Tickets
export const CREATE_TICKET = "CREATE_TICKET";

// Price
export const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";

// Discounts
export const APPLY_DISCOUNT = "APPLY_DISCOUNT";
