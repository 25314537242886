import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    retrieveTrains,
    setCurrentTrain,
} from "../actions/trains";
import classNames from "classnames";
import {Link} from "react-router-dom";

const lowestPrice = (stations) => {
    let lowestP=stations[0].adultPrice;
    Array.from(stations).forEach(station => {
        if(station.adultPrice < lowestP) {
            lowestP = station.adultPrice;
        }
    });
    return lowestP;
};

const TrainList = () => {
    const trains = useSelector(state => state.trains);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveTrains());
    }, []);

    return (
        <>
            {trains && trains.data &&
            trains.data.map((train, index) => (
                <div className={classNames("relative bg-gray-800 rounded-xl", { "mt-8": index !== 0})} key={train.id}>
                    <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 rounded-xl">
                        <img
                            className="w-full h-full object-cover rounded-l-xl overflow-hidden"
                            src={'/storage/' + train.image}
                            alt=""
                        />
                    </div>
                    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                        <div className="md:ml-auto md:w-1/2 md:pl-10">
                            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">{train.date} - {train.subheading}</h2>
                            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">{train.heading}</p>
                            <p className="mt-3 text-lg text-gray-300 whitespace-pre-line">
                                {train.description}
                            </p>

                            {train.stations.length > 0 && (<h3 className="pt-8 text-base font-semibold tracking-wider text-gray-300">Tidtabell:</h3>)}
                            {train.stations.map((station) => {
                                return station.embarkment ?
                                <React.Fragment key={station.name}>
                                    <p className="mt-3 text-lg text-gray-300">
                                        {station.name}: {station.departureTime}
                                    </p>
                                </React.Fragment>
                                :
                                <React.Fragment key={station.name}>
                                    <p className="mt-3 text-lg text-gray-300">
                                        {station.name}: {station.arrivalTime} (ankomst)
                                    </p>
                                </React.Fragment>
                            })}
                            {train.stations[0].adultPrice && (<p className="pt-6 text-base font-semibold text-gray-300">
                                Pris från: <span className="font-normal">{lowestPrice(train.stations)} kr</span></p>)
                            } 
                            {train.isPreliminary && (
                                <p className="mt-4 text-base text-gray-300">(Preliminär tid)</p>
                            )}
                            <div className="mt-8">
                                <div className="inline-flex shadow">
                                    {!train.isClosed &&
                                        <p className="mt-3 text-lg text-gray-300 whitespace-pre-line">
                                            <Link to={'/trains/' + train.id + '/station'}
                                                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                                            >
                                                Boka
                                            </Link>
                                            {train.link &&
                                                <a className="inline-flex items-center justify-center ml-5 px-5 py-3 border border-transparent text-base font-small rounded-md text-gray-900 bg-gray-300 hover:bg-gray-50"
                                                target="_blank"
                                                href={train.link}
                                                >
                                                Mer information om resan
                                                </a>
                                            }
                                        </p>
                                    }
                                    {train.isClosed &&
                                        <>
                                            <div className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-300">
                                                Boka
                                            </div>
                                            <p className="ml-3 mt-3 italic text-lg text-gray-300">Den här resan är inte öppen för bokning.</p>
                                            {train.link &&
                                            <a className="inline-flex items-center justify-center ml-5 px-5 py-3 border border-transparent text-base font-small rounded-md text-gray-900 bg-gray-300 hover:bg-gray-50"
                                               target="_blank"
                                               href={train.link}
                                            >
                                                Mer information om resan
                                            </a>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {trains.data && trains.data.length === 0 && (<p>Inga resor finns inplanerade för stunden.</p>)}
        </>
    );
};

export default TrainList;
