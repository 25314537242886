import * as React from "react"

function SeatIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={57}
            height={51.07}
            viewBox="0 0 57 51.07"
            overflow="visible"
            {...props}
        >
            <path d="M5.11 1.22H3.13C1.64 1.22.32 2.43.12 4.03c-.17 23.28-.17 23.28 0 43.05.2 1.57 1.52 2.78 3.01 2.78h.59c.44-.03 1.18.09 1.73.09.48 0 2.13.15 2.9.2 1.41-.08 1.21-.01 2.09.04.15.03 1.27.04 1.65.04.96 0 7.93.2 8.66.24 2.09-.02 12.04.16 16.22.34l9.81.25h.28c2.6 0 5.06-.99 6.93-2.81 1.92-1.9 3.01-4.4 3.01-7.1V9.94c-.03-2.7-1.09-5.23-3.03-7.13C52.05.91 49.47-.07 46.79 0l-9.81.25M2.65 46.77c-.2-15.44.12-26.15 0-42.45.05-.32.33-.57.51-.57l5.7-.33c.03 0-.01.43-.01.61.01 20.53.01 21.01 0 43.05.03.18-.03.25-.03.25H3.16c-.18 0-.46-.25-.51-.56zm51.8-5.64c0 2.02-.78 3.89-2.22 5.31-1.44 1.42-3.34 2.15-5.36 2.1l-31.52-.81c-1.34-.03-2.61-.09-3.88-.14 0-.15-.03-.67-.06-.82-.03-18.96.03-21.86-.06-42.45.03-.15-.01-.77-.01-.92 1.38-.02 2.66-.01 4-.04l31.52-.83h.2c1.95 0 3.77.73 5.16 2.1a7.37 7.37 0 012.22 5.31v31.19h.01z" />
        </svg>
    )
}

export default SeatIcon
