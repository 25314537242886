import {
    APPLY_DISCOUNT,
} from "../actions/types";

const initialState = null;

function discountReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case APPLY_DISCOUNT:
            return payload;
        default:
            return state;
    }
};

export default discountReducer;
