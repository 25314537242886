import React, {useState} from "react";
import Y1 from "../helpers/wagons/Y1";
import A2 from "../helpers/wagons/A2";
import AB3 from "../helpers/wagons/AB3";
import B1 from "../helpers/wagons/B1";
import B1S from "../helpers/wagons/B1S";
import B5 from "../helpers/wagons/B5";

export default function Wagon(props) {
    const { id, reservedSeats, announcedNumber, series, number } = props;

    const WagonType = {
        Y1: <Y1 id={id} reservedSeats={reservedSeats} number={number} announcedNumber={announcedNumber} />,
        A2: <A2 id={id} reservedSeats={reservedSeats} number={number} announcedNumber={announcedNumber} />,
        AB3: <AB3 id={id} reservedSeats={reservedSeats} number={number} announcedNumber={announcedNumber} />,
        B1: <B1 id={id} reservedSeats={reservedSeats} number={number} announcedNumber={announcedNumber} />,
        B1S: <B1S id={id} reservedSeats={reservedSeats} number={number} announcedNumber={announcedNumber} />,
        B5: <B5 id={id} reservedSeats={reservedSeats} number={number} announcedNumber={announcedNumber} />,
    };

    return (
        <div className="wagon">
            <h4 className="text-center text-base font-light text-indigo-600 tracking-wide uppercase mt-4">Vagn { announcedNumber ?? series + ' ' + number}</h4>
            {WagonType[series]}
        </div>
    )
}
