import {
    ADD_SEAT,
    REMOVE_SEAT,
} from "./types";

export const addSeat = (carriage, seat, announcedNumber, firstClass) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_SEAT,
            carriage: carriage,
            announcedNumber: announcedNumber,
            seat: seat,
            firstClass: firstClass
        });
    } catch (err) {
        console.log(err);
    }
};

export const removeSeat = (carriage, seat) => async (dispatch) => {
    try {
        dispatch({
            type: REMOVE_SEAT,
            carriage: carriage,
            seat: seat
        });
    } catch (err) {
        console.log(err);
    }
};
