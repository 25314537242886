import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../Spinner.js";
import {validateCoupon} from "../../actions/discount";
import {setTotalPrice} from "../../actions/price";

export default function SwishForm() {
    const [processing, setProcessing] = useState('');
    const [formSubmitted, setFormSubmitted] = useState('');
    const [validCode, setValidCode] = useState('');
    const [code, setCode] = useState(null);
    const dispatch = useDispatch();
    const train = useSelector(state => state.trains.currentTrain);
    const station = useSelector(state => state.activeStation);
    const travelers = useSelector(state => state.travelers);
    const { seats } = useSelector(state => state.activeSeats);
    const price = useSelector(state => state.price);
    const discount = useSelector(state => state.discount);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();
        setProcessing(true);
        setFormSubmitted(true);
        dispatch(validateCoupon(data));
    };

    useEffect(() => {
        if (discount && ! discount.error) {
            setValidCode(true);
            setCode(discount);
            if (discount.isFixed) {
                dispatch(setTotalPrice(price - discount.amount));
            } else {
                dispatch(setTotalPrice(price - (price * discount.amount/100)));
            }
        } else {
            setValidCode(false);
        }

    },[discount]);

    return (
        <div className="col-span-6 sm:col-span-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Rabattkod" {...register("couponcode", {required: true, maxLength: 20})} />
                {errors?.couponcode &&  (<p className="mt-2 text-sm text-red-600">Rabattkod måste anges.</p>) }
                {! validCode && formSubmitted && (<p className="mt-2 text-sm text-red-600">Rabattkod ej giltig.</p>) }
                {validCode && formSubmitted && code && (<p className="mt-2 text-sm text-green-600">Rabattkod "{code.code}" applicerad. Rabatt: {code.amount}{code.isFixed ? 'kr' : '%'}</p>) }
                <button
                    className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={processing}
                    id="submit"
                >
                    <span id="button-text">
                      {processing ? (
                          <Spinner />
                      ) : (
                          "Verifiera"
                      )}
                    </span>
                </button>
            </form>
        </div>
    );
}
