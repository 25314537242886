import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import { RadioGroup } from '@headlessui/react'

import {
    removeTraveler,
    addTraveler
} from "../actions/travelers";
import {useDispatch, useSelector } from "react-redux";
import {setCurrentTrain} from "../actions/trains";
import {setCurrentStation} from "../actions/stations";
import {nextStep} from "../actions/steps";

export default function Stations() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { currentTrain } = useSelector(state => state.trains);
    const activeStation = useSelector(state => state.activeStation);

    useEffect(() => {
        dispatch(setCurrentTrain(id));
    }, []);

    useEffect(() => {
        dispatch(setCurrentStation(selected));
    });

    const [selected, setSelected] = useState(null)

    return (
        <>
            {currentTrain && (
            <div>
                <div className="flow-root mt-6">
                    <div className="relative bg-gray-800 rounded-xl">
                        <div className="h-32 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 rounded-xl">
                            <img
                                className="w-full h-full object-cover rounded-l-xl overflow-hidden"
                                src={'/storage/' + currentTrain.image}
                                alt=""
                            />
                        </div>
                        <div className="relative max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 lg:py-8 mb-8">
                            <div className="md:ml-auto md:w-1/2 md:pl-10">
                                <p className="text-base uppercase tracking-wider text-gray-300">{currentTrain.date} - {currentTrain.subheading}</p>
                                <p className="mt-2 text-white font-extrabold tracking-tight text-lg">{currentTrain.heading}</p>
                                <p className="mt-3 text-sm text-gray-300 whitespace-pre-line">
                                    {currentTrain.description}
                                </p>
                                <p className="mt-3 text-sm text-gray-300 whitespace-pre-line">
                                    {currentTrain.link &&
                                    <a className="inline-flex items-center justify-center px-2 py-1 border border-transparent font-small rounded-md text-gray-900 bg-gray-300 hover:bg-gray-50"
                                       target="_blank"
                                       href={currentTrain.link}
                                    >
                                        Mer information om resan
                                    </a>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>

                    <h3 className="text-center text-base font-semibold text-indigo-600 tracking-wide uppercase">Välj påstigningsort</h3>
                    <RadioGroup value={selected} onChange={setSelected}>
                        <RadioGroup.Label className="sr-only">Välj påstningsort</RadioGroup.Label>
                        <div className="space-y-4">
                            {currentTrain.stations.map((station) => (
                                station.embarkment ?
                                <RadioGroup.Option
                                    key={station.name}
                                    value={station}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'ring-1 ring-offset-2 ring-indigo-500' : '',
                                            'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
                                        )
                                    }
                                >
                                    {({ checked }) => (
                                        <>
                                            <div className="flex items-center">
                                                <div className="text-sm">
                                                    <RadioGroup.Label as="p" className="font-medium text-gray-900">
                                                        {station.name}, {station.departureTime}
                                                        {currentTrain.isPreliminary && (
                                                            <span className="pl-4">(Preliminär tid)</span>
                                                        )}
                                                    </RadioGroup.Label>
                                                </div>
                                            </div>
                                            <RadioGroup.Description as="table" className="mt-2 text-xs sm:mt-0 sm:block sm:ml-4">
                                                { (currentTrain.firstClassRate === 0 && currentTrain.isFullPriceOnly) && (
                                                    <div className="font-medium text-gray-900">{station.adultPrice} kr</div>
                                                )}
                                                { currentTrain.firstClassRate !== 0 && (
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th className="bg-gray-50 border text-left px-2 py-1"></th>
                                                            <th className="bg-gray-50 border text-left px-2 py-1">2-klass</th>
                                                            <th className="bg-gray-50 border text-left px-2 py-1">1-klass</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className="border px-2 py-1">{ currentTrain.isFullPriceOnly ? 'Resenär:' : 'Vuxen (18+):' }</td>
                                                            <td className="border px-2 py-1">{station.adultPrice} kr</td>
                                                            <td className="border px-2 py-1">{station.adultPriceFirstClass} kr</td>
                                                        </tr>
                                                        {! currentTrain.isFullPriceOnly && (
                                                            <>
                                                                <tr>
                                                                    <td className="border px-2 py-1">Ungdom (13-17 år):</td>
                                                                    <td className="border px-2 py-1">{station.youthPrice} kr</td>
                                                                    <td className="border px-2 py-1">{station.youthPriceFirstClass} kr</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border px-2 py-1">Barn (7-12 år):</td>
                                                                    <td className="border px-2 py-1">{station.childPrice} kr</td>
                                                                    <td className="border px-2 py-1">{station.childPriceFirstClass} kr</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border px-2 py-1">Barn (0-6 år):</td>
                                                                    <td className="border px-2 py-1">{station.infantPrice} kr</td>
                                                                    <td className="border px-2 py-1">{station.infantPriceFirstClass} kr</td>
                                                                </tr>
                                                            </>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </RadioGroup.Description>
                                            <div
                                                className={classNames(
                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                    'absolute -inset-px rounded-lg border-2 pointer-events-none'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                                :
                                <React.Fragment key={station.name}>
                                </React.Fragment>
                            ))}
                        </div>
                    </RadioGroup>
                </div>
                <div className="mt-6">
                    {! currentTrain.isClosed && activeStation && (
                        <Link to={'/trains/' + id + '/travelers'}
                              className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Fortsätt
                        </Link>
                    )}
                    {! currentTrain.isClosed && ! activeStation && (
                        <div className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Fortsätt
                        </div>
                    )}
                    {currentTrain.isClosed && (
                        <div className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Den här resan är inte öppen för bokning.
                        </div>
                    )}
                </div>
            </div>
            )}
        </>
    )
}
