import {
    CREATE_TICKET,
} from "./types";

import TicketService from "../services/TicketService";

export const createTicket = (data) => async (dispatch) => {
    try {
        const res = await TicketService.create(data);
        dispatch({
            type: CREATE_TICKET,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};
