import React, {useState} from "react";
import {useSelector} from "react-redux";

import Spacer from "../../components/SeatSpacer";
import Seat from "../../components/Seat";
import EmptySeat from "../../components/EmptySeat";

export default function AB3(props) {
    const { id, reservedSeats, announcedNumber, number } = props;

    const activeSeats = useSelector(state => state.activeSeats);

    const isAvailable = (carriageId, seatNumber) => {
        return !reservedSeats.some(seat => {
            return (seat.carriageId === carriageId && seat.number === seatNumber)
        });
    };

    const isActive = (carriage, number) => {
        return Object.keys(activeSeats).some(seat => (seat.carriage === carriage && seat.seat === number))
    };

    return (
        <div className="wagon-box">
            <div className="vestibule first-class">
                <div className="wc-top-left">
                    WC
                </div>
            </div>
            <div className="wall-compartment-top first-class">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row first-class">
                <Seat carriage={id} announcedNumber={announcedNumber} number="1" firstClass={true} available={isAvailable(id, 1)} active={isActive(id, 1)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="2" firstClass={true} available={isAvailable(id, 2)} active={isActive(id, 2)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="3" firstClass={true} available={isAvailable(id, 3)} active={isActive(id, 3)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide first-class" />
            <div className="compartment-row first-class">
                <Seat carriage={id} announcedNumber={announcedNumber} number="4" reverse={true} firstClass={true} available={isAvailable(id, 4)} active={isActive(id, 4)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="5" reverse={true} firstClass={true} available={isAvailable(id, 5)} active={isActive(id, 5)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="6" reverse={true} firstClass={true} available={isAvailable(id, 6)} active={isActive(id, 6)} compartmentBottom={true} />
                <div className="bottom-wall-right" />
            </div>
            <div className="wall-compartment-top first-class">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row first-class">
                <Seat carriage={id} announcedNumber={announcedNumber} number="7" firstClass={true} available={isAvailable(id, 7)} active={isActive(id, 7)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="8" firstClass={true} available={isAvailable(id, 8)} active={isActive(id, 8)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="9" firstClass={true} available={isAvailable(id, 9)} active={isActive(id, 9)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide first-class" />
            <div className="compartment-row first-class">
                <Seat carriage={id} announcedNumber={announcedNumber} number="10" reverse={true} firstClass={true} available={isAvailable(id, 10)} active={isActive(id, 10)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="11" reverse={true} firstClass={true} available={isAvailable(id, 11)} active={isActive(id, 11)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="12" reverse={true} firstClass={true} available={isAvailable(id, 12)} active={isActive(id, 12)} compartmentBottom={true} />
                <div className="bottom-wall-right" />
            </div>
            <div className="wall-compartment-top first-class">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="compartment-row first-class">
                <Seat carriage={id} announcedNumber={announcedNumber} number="13" firstClass={true} available={isAvailable(id, 13)} active={isActive(id, 13)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="14" firstClass={true} available={isAvailable(id, 14)} active={isActive(id, 14)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="15" firstClass={true} available={isAvailable(id, 15)} active={isActive(id, 15)} compartmentBottom={true} />
                <div className="bottom-wall-left" />
            </div>
            <div className="wide first-class" />
            <div className="compartment-row first-class">
                <Spacer />
                <Seat carriage={id} announcedNumber={announcedNumber} number="20" firstClass={true} available={isAvailable(id, 20)} active={isActive(id, 20)} />
                <Spacer />
                <Seat carriage={id} announcedNumber={announcedNumber} number="19" firstClass={true} available={isAvailable(id, 19)} active={isActive(id, 19)} compartmentBottom={true} />
            </div>
            <div className="wide first-class" />
            <div className="compartment-row first-class">
                <Seat carriage={id} announcedNumber={announcedNumber} number="16" reverse={true} firstClass={true} available={isAvailable(id, 16)} active={isActive(id, 16)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="17" reverse={true} firstClass={true} available={isAvailable(id, 17)} active={isActive(id, 17)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="18" reverse={true} firstClass={true} available={isAvailable(id, 18)} active={isActive(id, 18)} compartmentBottom={true} />
            </div>
            <div className="wall-compartment-top first-class">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="41" available={isAvailable(id, 41)} active={isActive(id, 41)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="42" available={isAvailable(id, 42)} active={isActive(id, 42)} />
            </div>
            <div className="table-row">
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="45" reverse={true} available={isAvailable(id, 45)} active={isActive(id, 45)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="46" reverse={true} available={isAvailable(id, 46)} active={isActive(id, 46)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="43" available={isAvailable(id, 43)} active={isActive(id, 43)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="44" available={isAvailable(id, 44)} active={isActive(id, 44)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="49" reverse={true} available={isAvailable(id, 49)} active={isActive(id, 49)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="50" reverse={true} available={isAvailable(id, 50)} active={isActive(id, 50)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="47" available={isAvailable(id, 47)} active={isActive(id, 47)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="48" available={isAvailable(id, 48)} active={isActive(id, 48)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="53" reverse={true} available={isAvailable(id, 53)} active={isActive(id, 53)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="54" reverse={true} available={isAvailable(id, 54)} active={isActive(id, 54)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="51" available={isAvailable(id, 51)} active={isActive(id, 51)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="52" available={isAvailable(id, 52)} active={isActive(id, 52)} />
            </div>
            <div className="table-row">
                <div className="empty-table" />
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="57" reverse={true} available={isAvailable(id, 57)} active={isActive(id, 57)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="58" reverse={true} available={isAvailable(id, 58)} active={isActive(id, 58)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="55" reverse={true} available={isAvailable(id, 55)} active={isActive(id, 55)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="56" reverse={true} available={isAvailable(id, 56)} active={isActive(id, 56)} />
            </div>
            <div className="wall-row">
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
                <span className="wall" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="61" available={isAvailable(id, 61)} active={isActive(id, 61)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="62" available={isAvailable(id, 62)} active={isActive(id, 62)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="59" available={isAvailable(id, 59)} active={isActive(id, 59)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="60" available={isAvailable(id, 60)} active={isActive(id, 60)} />
            </div>
            <div className="table-row">
                <div className="empty-table" />
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="65" available={isAvailable(id, 65)} active={isActive(id, 65)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="66" available={isAvailable(id, 66)} active={isActive(id, 66)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="63" reverse={true} available={isAvailable(id, 63)} active={isActive(id, 63)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="64" reverse={true} available={isAvailable(id, 64)} active={isActive(id, 64)} />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="68" available={isAvailable(id, 68)} active={isActive(id, 68)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="67" available={isAvailable(id, 67)} active={isActive(id, 67)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="70" reverse={true} available={isAvailable(id, 70)} active={isActive(id, 70)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="69" reverse={true} available={isAvailable(id, 69)} active={isActive(id, 69)} />
            </div>
            <div className="table-row">
                <div className="table" />
            </div>
            <div className="seat-row">
                <Seat carriage={id} announcedNumber={announcedNumber} number="72" reverse={true} available={isAvailable(id, 72)} active={isActive(id, 72)} />
                <Seat carriage={id} announcedNumber={announcedNumber} number="71" reverse={true} available={isAvailable(id, 71)} active={isActive(id, 71)} />
            </div>
            <div className="vestibule">
                <div className="wc-top-right">
                    WC
                </div>
            </div>
        </div>
    )
}
