import {
    ADD_SEAT,
    REMOVE_SEAT,
} from "../actions/types";

const initialState = {
    seats: []
};

function reservedSeatsReducer(state = initialState, action) {
    const {carriage, announcedNumber, seat, firstClass, type} = action;

    switch(type) {
        case 'ADD_SEAT':
            return {
                ...state,
                seats: [{carriage: carriage, announcedNumber: announcedNumber, seat: seat, firstClass: firstClass}, ...state.seats]
            };
        case 'REMOVE_SEAT':
            const filteredSeats = state.seats.filter(item => {
                if (item.carriage !== carriage) {
                    return true;
                }

                if (item.carriage === carriage && item.seat === seat) {
                    return false;
                }

                return true;
            });

            return {
                ...state,
                seats: filteredSeats
            };
        default:
            return state;
    }
};

export default reservedSeatsReducer;
