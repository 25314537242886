import React from "react";
import ReservationInfoHeader from "./ReservationInfoHeader";
import SelectedTrain from "./SelectedTrain";
import SelectedStation from "./SelectedStation";
import TravelerList from "./TravelerList";
import SelectedSeats from "./SelectedSeats";
import CalculatedPrice from "./CalculatedPrice";
import CheckoutPrice from "./CheckoutPrice";

const ReservationInfo = (props) => {
    const { showHeader, checkout = false } = props

    return (
        <div className="px-4 py-4 mt-4 sm:px-6 font-medium">
            {showHeader && <ReservationInfoHeader />}
            <SelectedTrain />
            <SelectedStation />
            <TravelerList />
            <SelectedSeats />
            {! checkout && <CalculatedPrice />}
            {checkout && <CheckoutPrice />}
        </div>
    );
};

export default ReservationInfo;
