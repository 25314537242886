import http from "../http-common";

const getAll = () => {
    return http.get("/trains");
};

const get = id => {
    return http.get(`/trains/${id}`);
};

const TrainService = {
    getAll,
    get
};

export default TrainService;
