import React, {useState} from "react";
import SeatIcon from "./icons/Seat";
import classNames from "classnames";

export default function EmptySeat(props) {
    const { id } = props;

    return (
        <div key={id} className="seat-spacer">
        </div>
    )
}
