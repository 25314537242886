import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {Switch} from '@headlessui/react'
import { useFormContext } from "react-hook-form";

const DietPreference = (props) => {
    const {travelerIndex} = props;
    const [dietPreference, setDietPreference] = useState(false);
    const dispatch = useDispatch();
    const travelers = useSelector(state => state.travelers);
    const { currentTrain: { dietPreferences } } = useSelector(state => state.trains);
    const { register } = useFormContext();

    return (
        <>
            <Switch.Group as="div" className="flex items-center">
                <Switch
                    checked={dietPreference}
                    onChange={setDietPreference}
                    className={classNames(
                        dietPreference ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                >
                    <span className="sr-only">Use setting</span>
                    <span
                        className={classNames(
                            dietPreference ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                    >
                    <span
                        className={classNames(
                            dietPreference ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                    >
                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <span
                        className={classNames(
                            dietPreference ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                    >
                        <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                            <path
                                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
                        </svg>
                    </span>
                </span>
                </Switch>
                <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-900">Kostavvikelser </span>
                </Switch.Label>
            </Switch.Group>
            { dietPreference &&
            <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                {dietPreferences.map((preference, preferenceIndex) => (
                    <div key={preferenceIndex} className="relative flex items-start py-4">
                        <div className="min-w-0 flex-1 text-sm">
                            <label title={preference.title} htmlFor={`preference-${preference.id}`} className="font-medium text-gray-700 select-none">
                                {preference.name}
                            </label>
                        </div>
                        <div className="ml-3 flex items-center h-5">
                            <input
                                {...register(`travelers.${travelerIndex}.preference.${preference.id}`)}
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                        </div>
                    </div>
                ))}
            </div>
            }
        </>
    )
};

export default DietPreference;
