import {
    CREATE_TICKET,
} from "../actions/types";

const initialState = null;

function ticketReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_TICKET:
            return payload;
        default:
            return state;
    }
};

export default ticketReducer;
