import http from "../http-common";

const getAll = () => {
    return http.get("/tickets");
};

const get = id => {
    return http.get(`/tickets/${id}`);
};

const create = data => {
    return http.post("/tickets", data);
};

const TicketService = {
    get,
    create,
};

export default TicketService;
