import {
    RETRIEVE_STEPS,
    SET_STEP,
} from "../actions/types";

const initialState = [
    {id: 1, name: 'Välj resa', href: '#', status: 'current'},
    {id: 2, name: 'Välj station', href: '#', status: 'upcoming'},
    {id: 3, name: 'Antal resenärer', href: '#', status: 'upcoming'},
    {id: 4, name: 'Välj platser', href: '#', status: 'upcoming'},
    {id: 5, name: 'Betala', href: '#', status: 'upcoming'},
];

function stepReducer(state = initialState, action) {
    const { currentStep: id } = action;

    switch(action.type) {
        case 'RETRIEVE_STEPS':
            return state;
        case 'SET_STEP':
            const newState = state.map(step => {
                if (step.id > id) {
                    return {
                        ...step,
                        status: "upcoming",
                    }
                }

                if (step.id < id) {
                    return {
                        ...step,
                        status: "complete",
                    }
                }

                return {
                    ...step,
                    status: "current",
                }
            });

            return newState;
        default:
            return state;
    }
};

export default stepReducer;
