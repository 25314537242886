import {useSelector} from "react-redux";
import React from "react";

const SelectedSeats = () => {
    const { seats } = useSelector(state => state.activeSeats);

    return (
        <>
            { seats.length > 0 && (
                <>
                    <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Platser</h3>
                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                        {seats.map((seat, index) => (
                            <li key={index} className="col-span-1 flex shadow-sm rounded-md">
                                <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
                                    <div className="flex-1 px-4 py-2 text-sm truncate">
                                        <span className="text-gray-900 font-medium hover:text-gray-600">
                                            Vagn {seat.announcedNumber} - Plats {seat.seat}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};

export default SelectedSeats;
