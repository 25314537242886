import {
    RETRIEVE_TRAINS,
    SET_ACTIVE_TRAIN,
} from "./types";

import TrainService from "../services/TrainService";

export const retrieveTrains = () => async (dispatch) => {
    try {
        const res = await TrainService.getAll();

        dispatch({
            type: RETRIEVE_TRAINS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const setCurrentTrain = (id) => async (dispatch) => {
    try {
        const res = await TrainService.get(id);
        dispatch({
            type: SET_ACTIVE_TRAIN,
            payload: res.data.data,
        });
    } catch (err) {
        console.log(err);
    }
};
