import {useSelector} from "react-redux";
import React from "react";

const SelectedStation = () => {
    const activeStation = useSelector(state => state.activeStation);
    const currentTrain = useSelector(state => state.trains.currentTrain);

    return (
        <>
            {activeStation &&
            <>
                <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Påstigningsstation</h3>
                <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                        <span className="text-gray-900 font-medium hover:text-gray-600">
                            {activeStation.name}, {activeStation.departureTime}
                            {currentTrain.isPreliminary && (<span className="pl-4">(Preliminär tid)</span>)}
                        </span>
                    </div>
                </div>
            </>
            }
        </>
    );
};

export default SelectedStation;
