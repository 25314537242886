import http from "../http-common";

const verifyCoupon = data => {
    return http.post("/coupons/validate", data)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        });
};

const CouponService = {
    verifyCoupon,
};

export default CouponService;
