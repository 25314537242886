import {
    SET_ACTIVE_STATION,
} from "../actions/types";

const initialState = null;

function stationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_ACTIVE_STATION:
            return payload;
        default:
            return state;
    }
};

export default stationReducer;
