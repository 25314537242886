import {Link} from "react-router-dom";
import React from "react";

export default function Header() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Nässjö Järnvägsmuseum</h2>
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        <Link to={"/"}>
                            Våra resor
                        </Link>
                    </p>
                    <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                        Välkommen att boka biljetter till en av föreningens resor. Du hittar alla våra resor och bokar samt betalar enkelt direkt på nätet.
                    </p>
                </div>
            </div>
        </div>
    )
}
