import {
    RETRIEVE_TRAINS,
    SET_ACTIVE_TRAIN,
} from "../actions/types";

const initialState = {
    trains: [],
    currentTrain: null
};

function trainReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RETRIEVE_TRAINS:
            return payload;
        case SET_ACTIVE_TRAIN:
            return {
                ...state,
                currentTrain: payload,
            };
        default:
            return state;
    }
};

export default trainReducer;
